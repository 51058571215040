import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import { baseURL } from './utils/utils'

import {
  create,
  NButton,
  NSpace,
  NSlider,
  NMessageProvider,
  NModal,
  NCard,
} from 'naive-ui'

axios.defaults.baseURL = baseURL

// 请求拦截
// 携带token
axios.interceptors.request.use(
  (config) => {
    const jwt = sessionStorage.getItem('user_token')
    jwt ? (config.headers.Authorization = `Bearer ${jwt}`) : ''
    // 设置加载进度条(开始..)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  (config) => {
    return config
  },
  (e) => {
    console.error(e)
  }
)

const naive = create({
  components: [NButton, NSpace, NSlider, NMessageProvider, NModal, NCard],
})

createApp(App).use(store).use(router).use(naive).mount('#app')
