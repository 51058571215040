<template>
  <n-message-provider>
    <router-view />
  </n-message-provider>
</template>

<style lang="scss">
@import "./assets/icon.css";
body {
  margin: 0;
}
</style>
